import { Typography } from "@mui/material";
import { FC, useId, useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { isFunction } from "utils/objectUtils";
import { css } from "@emotion/react";

export interface CollapsedProps {
    title?: string
    children?: Function
    stateless?: boolean
}

const styleDef = {
    root: css({
        '& > input': {
            height: '0px',
            width: '0px',
            overflow: 'hidden',
            opacity: '0',
            padding: '0 !important',
            margin: '0 !important',
        },
        '& > label': {
            userSelect: 'none',
        },
        '& > input:checked~label svg': {
            transform: 'rotate(180deg)',
        },
        '& .childCont': {
            height: '0px',
            overflow: 'hidden',
        },
        '& input:checked~.childCont': {
            height: 'auto',
            overflow: 'auto',
        },
    }),
}

export const Collapsed: FC<CollapsedProps> = ({title, children, stateless}) => {

    const id = useId()
    const checkboxId = `cbk-${id}`
        
    
    const [open,setOpen] = useState<boolean>(false)

    title = title || 'Expand'

    return (
        <div css={styleDef.root}>
            {stateless && <input type="checkbox" id={checkboxId} />}
            <Typography role="button" onClick={stateless != true ? ()=>setOpen(!open):undefined} component="label" htmlFor={stateless ? checkboxId:null} color="text.secondary" sx={{cursor: 'pointer', display: 'inline-flex', verticalAlign: 'center'}}>{title} <KeyboardArrowDownIcon sx={{ transform: open ? 'rotate(180deg)':null}} /></Typography>
            {(open) && children && (isFunction(children) ? children() : children)}
            {(stateless) && children && <div className="childCont">{isFunction(children) ? children() : children}</div>}
        </div>
    )
}