export const handleResponseDataGetContent = (data) => {
    const resdata = handleResponseData(data)
    if (!resdata ||resdata.content === null  ||resdata.content === undefined) {
        return Promise.resolve(null)
    }
    return Promise.resolve(resdata.content)
}

export const handleResponseData = (data) => {
    if (data === null) {
        return null
    }
    if (data.headers && data.request && data.status) { 
        // Is axios response
        return handleResponseBody(data.data)
    } else {
        // Is anything, e.g. an already unrapped axios response body.
        return handleResponseBody(data)
    }
}

/**
 * @param {*} body 
 *      Can be null
 * @returns 
 */
const handleResponseBody = (body) => {
    return {
        content: getContentFromBody(body),
        page: body?.page || null,
        _parsed: true
    }
}

const getContentFromBody = (data) => {
    if (data === null) {
        return null;
    }
    if (data._embedded?.items) {
        return data._embedded.items
    } 
    else if (data._embedded && Object.keys(data._embedded).length === 1) {
        //get first emmbedded list
        return data._embedded[Object.keys(data._embedded)[0]]
    } 
    if (data.items && !data.name && Array.isArray(data.items)) {
        return data.items
    } 
    else if (data.content && !data.name) {//assume its nested content. double check by checking of there is a name (just guessing that there should be a name in this case)
        return data.content
    } 
    else if ( Array.isArray( data ) ) {
        return data
    }
    else if (data == null || Object.keys(data).length === 0) {
        return null
    }
    else if (Object.keys(data).length === 1 && (data.page !== null && data.page !== undefined)) {
        return []
    }
    else { 
        return data
    }
}