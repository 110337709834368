import { Button, IconButton, lighten, Typography, Link as MuiLink } from "@mui/material";
import Link from "next/link";
import { FC, forwardRef, MouseEventHandler, PropsWithChildren } from "react";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { FunctionComponent } from "react";
import { notNullNotUndefined } from "utils/objectUtils";

export const linkStyle = {
    color: '#1264a3',
    fontWeight: 500,
    '&:hover': {
        cursor: 'pointer',
        color: '#65a7f2',
    }
}

const css = {
    startText: theme=>({
        color: theme.palette.text.secondary,
        fontSize: '1em',
    }),
    textSx: {
        color: '#1264a3',
        fontSize: '1em',
        fontWeight: '500',
    },
    /**
     * Copy original 'link' styles.
     * 
     * Cannot style a tag directly because emotion CSS breaks next js link router if applied on a tag directly
     */
    //
    linkContainer: theme=>({ 
        '& a': {
            color: '#1264a3',
            textDecoration: 'none',
            '&:hover': {
                //textDecoration: 'underline',
                cursor: 'pointer',
                color: lighten(theme.palette.primary.main,0.3),
            },
            '&:hover *': {
                color: lighten(theme.palette.primary.main,0.3),
            }
        }
    }),
    link: theme=>({
        color: '#1264a3',
        textDecoration: 'none',
        '&:hover': {
            //textDecoration: 'underline',
            cursor: 'pointer',
            color: lighten(theme.palette.primary.main,0.3),
        },
        '&:hover *': {
            color: lighten(theme.palette.primary.main,0.3),
        }
    }),
    linkBtn: theme=>({
        background: 'none !important',
        padding: '0',
        minWidth: '0px',
        textDecoration: 'none',
        '&:hover': {
            //textDecoration: 'underline',
            cursor: 'pointer',
            color: lighten(theme.palette.primary.main,0.3),
            background: 'none !important',
        },
        '&:hover *': {
            color: lighten(theme.palette.primary.main,0.3),
        }
    }),
}

export type ElementLinkProps = {
    href: string
    target?: string
    inIframe?: boolean
    rel?: string
    sx?: any
    disableClientSideNav?: boolean
    className?: string
    download?: string
    fullWidth?: boolean
    prefetch?: boolean
    onClick?:()=>void
}

export const ElementLink: FunctionComponent<PropsWithChildren<ElementLinkProps>> = forwardRef<HTMLAnchorElement, PropsWithChildren<ElementLinkProps>>(({children, href, target, download, inIframe, rel, sx, disableClientSideNav, fullWidth, prefetch, ...props}, ref) => {

    /* does not work in safari (cross origin). not necessary anyway? 
    const handleInsideIframeClick = () => {
        parent.window.open(href);
    }

    if (inIframe && target === '_blank') {
        return (
            <a href={'#'} className={joinClasses(classNames)} onClick={handleInsideIframeClick}>
                {children}
            </a>
        )
    } */

    const css = {
        textDecoration: 'none',
        width: fullWidth ? '100%':null,
    }

    const renderLinkHtml = () => {
        return <a href={href} target={target} className={props.className} ref={ref} rel={rel} css={[css,sx]} download={download} onClick={props?.onClick}>
            {children}
        </a>
    }

    if (disableClientSideNav) {
        return renderLinkHtml();
    }

    return (
        <Link href={href} prefetch={prefetch} {...props}>
            {renderLinkHtml()}
        </Link>
    )

})

export const LinkNormal = ({startText, href, children, target, textSx, fontWeight, onClick, color, ...props}) => {
    
    const linkCssEffective = [
        css.linkContainer,
    ]

    if (textSx?.fontSize) {
        linkCssEffective.push({
            // @ts-ignore
            '& a': {
                fontSize: textSx.fontSize + ' !important',
            }
        })
    }

    if (fontWeight && !textSx?.fontWeight) {
        textSx = {...(textSx || {}), fontWeight}
    }

    return (
        <span css={linkCssEffective}>
            {startText && (
                <><Typography component="span" sx={css.startText}>{startText}</Typography>&nbsp;</>
            )}
            <Link href={href} {...props}>
                <a target={target} onClick={onClick}>
                    <Typography component="span" sx={[css.textSx, textSx, color? {color}:null]} >{children}</Typography>
                </a>
            </Link>
        </span>
    );

}

/**
 * Button that looks like a link

 * @returns 
 */
export const LinkButtonNormal: FC<PropsWithChildren<{onClick: MouseEventHandler<HTMLButtonElement>, style?: any, textSx?: any }>> = ({onClick, children, style, textSx,...other}) => {

    return (
        <Button onClick={onClick} sx={[css.link, css.linkBtn, style]} {...other}>
            {/** @ts-ignore */}
            <Typography component="span" sx={[css.textSx, textSx]}>{children}</Typography>
        </Button>
    )

}

export const MuiLinkOutlined = (props) => {

    const color = props.color || 'primary'

    const sx = (theme)=>({
        border: '1px solid ' + theme.palette[color].main,
        padding: '8px 22px',
        borderRadius: '4px',
        fontWeight: 600,
    })

    return (
        <MuiLink {...props} sx={[sx, props.sx]} underline="none"/>
    )

}

export const OpenInNewIconLink = ({title, href, sx}:{ title?: string, href: string, sx: any }) => {

    return (
        <ElementLink href={href} target="_blank">
            <IconButton size="small" sx={sx}>
                {title && <>{title}&nbsp;</>}<OpenInNewIcon fontSize="small" className="icon" />
            </IconButton>
        </ElementLink>
    )

}

export const ButtonPost = ({action, children, onSubmit, ...props}) => {
    notNullNotUndefined(action)

    return (
        <form action={action} method="post" onSubmit={onSubmit}>
            <Button type="submit" {...props}>{children}</Button>
        </form>
    )
}