/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'

import React, { FunctionComponent } from "react"


export interface AbsoluteProps extends React.ComponentPropsWithoutRef<"button"> {
    top?:string|number
    left?:string|number
    bottom?:string|number
    right?:string|number
    style?: any
    center?: boolean
  }

export const Absolute: FunctionComponent<AbsoluteProps> = ({top, left, bottom, right, children, className, style, center}) => {

    let transform = null
    if (center) {
        const yCustom = top??bottom??null
        const xCustom = left??right??null
        top = bottom ? null : top ?? '50%'
        left = '50%'
        transform = `translate(${xCustom?'0':'-50%'}, ${yCustom?'0':'-50%'})`
    }

    const styles = {
        root: css({
            position: 'absolute',
            top,
            left,
            bottom,
            right,
            transform,
        })
    }

    return (
        <div css={[styles.root, style]} className={className}>{children}</div>
    )

}