import { Box, Button, ButtonGroup, darken, keyframes, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState, useRef, forwardRef } from 'react';
import { ConditionalWrapper } from 'modules/picasso-ui/ConditionalWrapper';
import LaunchIcon from '@mui/icons-material/Launch';
import { useTranslation } from 'next-i18next';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';

const useStyles = makeStyles(()=>({
  buttonIncentive: {
    background: '#00a753',
    color: '#fff',
    '&:hover': {
      background: darken('#00a753', 0.2),
    },
  },
  buttonGroupIncentive: {
    boxShadow: 'rgb(0 167 83 / 22%) 0px 8px 16px 0px'
  }
}));

const pressKeyframes = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.92);
  }
  to {
    transform: scale(1);
  }
`;

const bounceKeyframes = keyframes`
  50% {
    transform: rotate(5deg) translate(10px, -30px);
  }
  to {
    transform: scale(0.9) rotate(10deg) translate(10px, -40px);
    opacity: 0;
  }
`;

export const CopyLinkButton = forwardRef(({text, buttonVariant, buttonSize, copyContent, showGotoButton, fullWidth, useIncentiveButtonStyle, 
  buttonColor, buttonProps, showCheckOnSuccess, successText, showRipple, hideText, showIcon, showTooltip, tooltipTitle, onClick, iconFontSize, ...otherProps}, ref) => {

    const classes = useStyles();

    const { t } = useTranslation('common')

    const buttonClassName = [];
    if (useIncentiveButtonStyle === true) {
      buttonClassName.push(classes.buttonIncentive);
    }

    const buttonGroupClassName = [];
    if (useIncentiveButtonStyle === true) {
      buttonGroupClassName.push(classes.buttonGroupIncentive);
    }

    buttonSize = buttonSize || buttonProps?.buttonSize || undefined

    const [copySuccess, setCopySuccess] = useState(null)
    const textAreaRef = useRef(null);

    const buttonRef = useRef()
  
    function copyToClipboard(e) {

      textAreaRef.current.select();
      document.execCommand('copy');
      e.target.focus();

      setCopySuccess(c=>{
        if (!c) {
          return 1
        }
        return c++
      })
      
      setTimeout(()=> {
        setCopySuccess(null);
      }, 500);

      onClick?.(e)

/*       else {
        setTimeout(()=> {
          buttonRef.current.blur()
        }, 1000);
      } */
    };

    const handleGotoClick = () => {
      window.open(copyContent, '_blank');
    }

    text = text ?? t('action.linkCopy')

    const showCheckNow = showCheckOnSuccess !== false && copySuccess != null

    const buttonSx = [
      {
        whiteSpace: 'nowrap', 
        borderRight: showGotoButton ? 'none' : null, 
        fontWeight: '500', 
        position: 'relative',
      },
    ]

    if (buttonProps?.sx) {
      buttonSx.push(buttonProps.sx)
    }

    const showRippleNow = showRipple && copySuccess != null

    if (showRippleNow) {
      buttonSx.push({animation: `${pressKeyframes} 0.4s ease`})
    }

    tooltipTitle = showTooltip ? (tooltipTitle || "Copy " + text) : ''

    return (
      <div style={{position: 'relative', }}>
        {
         /* Logical shortcut for only displaying the 
            button if the copy command exists */
            typeof document !== 'undefined' && document.queryCommandSupported('copy') &&
            <div style={{display: fullWidth? null:'inline-block'}} ref={ref} {...otherProps} >
              <ConditionalWrapper 
                condition={showGotoButton}
                wrapper={(children)=><ButtonGroup fullWidth={fullWidth} variant={buttonVariant} color={buttonColor} className={buttonGroupClassName.join(' ')}>{children}</ButtonGroup>}
              >
                <Tooltip title={tooltipTitle || ''}>
                  <Button
                      ref={buttonRef}
                      className={buttonClassName.join(' ')}
                      onClick={copyToClipboard} 
                      variant={buttonVariant}
                      size={buttonSize}
                      color={buttonColor} 
                      {...buttonProps}
                      sx={buttonSx}
                      >
                    {showCheckNow ? <>&nbsp;{successText}<CheckIcon fontSize="small" />&nbsp;</> : (
                      hideText !== true ?
                        <span style={{textAlign: 'center', display: 'inline-block'}} className="text">{text}</span>
                        : 
                        null
                      )
                    }
                    {showRippleNow && <CopyRipple text={text} />}
                    {showIcon && <ContentCopyRoundedIcon sx={{marginLeft: hideText ? 0 : '4px', fontSize: iconFontSize??'12px !important', color: 'greyDark'}} />}
                  </Button> 
                </Tooltip>
                {showGotoButton && (
                  <Button
                    color={buttonColor}
                    className={buttonClassName.join(' ')}
                    size={buttonSize}
                    onClick={handleGotoClick}
                    style={{width: '25px'}}
                  >
                    <LaunchIcon size="small" style={{fontSize: '12px', width: '20px'}}/>
                  </Button>
                )}
              </ConditionalWrapper>
            </div>
        }
        <form>
          <textarea
            style={{height: '1px', width: '1px', position: 'absolute', top: '-1500px', opacity: 0.01, right: '-4000' }}
            ref={textAreaRef}
            value={copyContent}
            readOnly={true}
          />
        </form>
      </div>
    );

})

const CopyRipple = ({text}) => {

  return (
    <Box
      sx={
        {
          textAlign: 'center',
          position: 'absolute',
          left: '9px',
          display: 'inline-block',
          animation: `${bounceKeyframes} 0.4s ease forwards`,
        }
      }
      > 
      {text}
    </Box>
  )
  
}