import { v4 as uuidv4 } from 'uuid';
import { getAxiosDefault } from 'apiClient/config/apiAxiosConfig';
import { isHavingValue, notNullNotUndefined } from './objectUtils';
import { notEmpty } from './stringUtils';

export const entityId = () => uuidv4();

/**
 * 
 * @param {*} entity 
 * @param {*} linkName 
 * @returns 
 *      Never null
 */
export const findLink = (entity, linkRel) => {
    if (!entity) {
        throw new Error('entity required');
    }
    if (!linkRel) {
        throw new Error('linkRel required');
    }

    if (entity.links) {
        let result = entity.links.find(l=>l.rel===linkRel)
        if (!result) {
            throw new Error('entity link with name not found (expected in "links" array). linkRel='+linkRel);
        }
        return result;
    }

    if (!entity._links) {
        throw new Error('entity has no links');
    }
    const link = entity._links[linkRel];
    if (!link) {
        throw new Error('entity link with name not found (expected in "_links" object). linkRel='+linkRel);
    }
    return link;
}

/**
 * 
 * @param {*} entity 
 * @param {*} linkName 
 * @returns 
 *      Never null
 */
 export const hasLink = (entity, linkRel) => {
    if (!entity) {
        throw new Error('entity required');
    }
    if (!linkRel) {
        return false
    }

    //check for field "links" with array inside
    //for some reason api is not consistent and rendered link as array in some cases (and field name "links" instead of "_links")
    if (entity.links && isHavingValue(entity.links.find(l=>l.rel === linkRel))) {
        return true;
    }

    if (!entity._links) {
        return false
    }
    const link = entity._links[linkRel];
    if (!link) {
        return false
    }
    return true;
}

export const getEntityLinkName = (entity, linkRel) => {
    const link = findLink(entity, linkRel);
    return link.name;
}

export const executeEntityLink = (entity, linkRel) => {
    const link = findLink(entity, linkRel);

    if (linkRel === 'delete') {
        return getAxiosDefault().delete(link.href)
    } 
    else if (linkRel.startsWith('delete')) {
        return getAxiosDefault().delete(link.href)
    } 
    else {
        return getAxiosDefault().get(link.href)
    }
}

export const withHrefValue = (href, key, value) => {
    notNullNotUndefined(href);
    notEmpty(key);
    notEmpty(value);
    return href.replace('%7B'+key+'%7D', value);
}

/**
 * @param {*} filter 
 *      null allowed
 */
export const filterToQueryParams = (filter) => {
    if (!filter) {
        return null
    }

    const params = {}
    Object.keys(filter).forEach((k)=>{
        if (k === '_touched') {
            return;
        }
        const v = filter[k]
        if (!isHavingValue(v) || v.length === 0) {
           return;
        }
        if (Array.isArray( v )) {
            params[k] = v.join(',')
        } 
        else {
            params[k] = v
        }
    })
    return params
}