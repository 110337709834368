import { Theme, Typography, TypographyProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ElementType, FC, ReactElement } from 'react';
import { ForwardedRef, forwardRef, Fragment, PropsWithChildren, ReactNode } from "react";


export interface MultilineTextProps {
    ignoreOnXs?: boolean
    className?: string
    renderTextElem?: (props:{className: string, ref: ForwardedRef<HTMLSpanElement>, children: ReactNode}) => ReactElement
    ref?: ForwardedRef<HTMLSpanElement>
    component?: ElementType
}

const useStyles = makeStyles((theme: Theme)=>({
    root: {
        whiteSpace: 'pre-line',
        display: 'inline-block',
    },
    ignoreOnXs: {
        [theme.breakpoints.down('sm')]: {
            whiteSpace: 'normal',
        }
    }
}));

/**
 * 
 * Keeps \n as line break
 * 
 * @param {*} param0 
 * @returns 
 */
export const MultilineText: FC<PropsWithChildren<MultilineTextProps>> = forwardRef<HTMLSpanElement, PropsWithChildren<MultilineTextProps>>(({children, ignoreOnXs, className, renderTextElem, ...otherProps}, ref) => {

    const classes = useStyles()

    const classNames = [classes.root]

    if (ignoreOnXs === true) {
        classNames.push(classes.ignoreOnXs)
    }
    if (className) {
        classNames.push(className)
    }

    let effective;
    try {
        if (typeof children === 'string' || children instanceof String) {
            effective = children.split('\\n').map((str,idx)=><Fragment key={idx}>{str}<br/></Fragment>);
        } else {
            //else: not solved now if multiple children
            console.error("MultilineText. expected children to be string. got type: " + (typeof children))
        }
    } catch (error) {
        console.error(error)
        let childrenLength = null;
        if (Array.isArray(children)) {
            childrenLength = children.length
        }
        console.error('error in MultilineText. got type: ' + (typeof children) + '. children length: '+childrenLength+'. children: ' + children)
        effective = children
    }

    if (renderTextElem) {
        return renderTextElem({className: classNames.join(' '), ref, children: effective, ...otherProps})
    } else {
        return (<span className={classNames.join(' ')} ref={ref}>{effective}</span>)
    }
    
})

export const MultilineTypography = forwardRef<HTMLSpanElement, MultilineTextProps & TypographyProps>((props, ref) => {
    
    const renderTextElem = (p) => <Typography {...p}/>

    return <MultilineText {...props} renderTextElem={renderTextElem} ref={ref}/>
})