import { useRef, useState } from "react"
import useSWR from "swr"
import { isEqual, isFunction, isHavingValue, isNullOrUndefined, notNullNotUndefined } from "./objectUtils"
import { handleResponseDataGetContent as handleResponseDataGetContentExternal,  handleResponseData as handleResponseDataExternal  } from "./httpUtils"

export const fetchAndParse = (promiseFnc, params) => {
    return promiseFnc(params)
        .then(handleResponseData)
        .catch(handleError)
}

/**
 * searches for the 'item' in the response
 * 
 * Reason is that the way spring restuns the data is not uniform, because it depends if paginations is used, spring hateoas is used, etc...
 * 
 * @param {*} data 
 * @returns 
 */
export const handleResponseData = (data) => {
    return handleResponseDataExternal(data)
}

export const handleResponseDataGetContent = (data) => {
    return handleResponseDataGetContentExternal(data)
}

const handleError = (e) => {
    if (e) {
        throw e
    } else {//response error handler might swallow the error
        throw {
            type: 'unknown'
        }
    }
}

export const useResolvedImmutable = (key, promiseFnc, options) => {

    return useResolvedItemV2(key, promiseFnc, {
        ...options,
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false

    })

}

export const useResolvedItemV3 = (key, promiseFnc, options) => {

    const { size: initialPageSize, pageIndex: initialPageIndex, sort: initialSort, query, ...otherOptions } = options

    const [pageIndex, setPageIndex] = useState(initialPageIndex??0)
    const [pageSize, setPageSize] = useState(initialPageSize??25)

    notNullNotUndefined(pageIndex)
    notNullNotUndefined(pageSize)

    const fetchKey = key ? `${key}?page=${pageIndex}&size=${pageSize}${initialSort?'sort='+encodeURIComponent(initialSort):''}${query?query:''}` : null

    const getParams = () => {
        return { page: pageIndex, size: pageSize, sort: initialSort, q: query }
    }
    
    const swr = useSWR(fetchKey, ()=>{
        try {
            // Mostly used for debug
            if (options.onFetch) {
                options.onFetch()
            }
            return fetchAndParse(promiseFnc,  getParams())
        } catch (e) {
            console.error(e)
            throw e
        }
    }, 
    {   
        ...otherOptions
    })

    return { 
        swr, 
        get page() {
            return swr.data?.page
        },
        setPageIndex,
        setPageSize,
        get loading () {
            return !swr.error && isNullOrUndefined(swr.data)
        },
        // Effective fetch key
        fetchKey
     }
}

export const useResolvedItemV2 = (key, promiseFnc, options) => {
    notNullNotUndefined(promiseFnc)

    const stateDependencies = useRef({}).current

    options = options || {}

    const { converter, size, pageIndex: initialPageIndex, ...otherOptions } = options

    // Mostly used for debug
    if (options.onRender) {
        options.onRender()
    }

    const [pageIndex, setPageIndex] = useState(initialPageIndex)

    const fetchKey = isHavingValue(pageIndex) ? `${key}?page=${pageIndex}` : key

    const getParams = () => {
        let params = null;
        if (isHavingValue(size) || isHavingValue(pageIndex)) {
            params = {
                page: pageIndex,
                size: size || 25
            }
        }
        return params
    }



    const swr = useSWR(fetchKey, ()=>{
        try {
            // Mostly used for debug
            if (options.onFetch) {
                options.onFetch()
            }
            return fetchAndParse(promiseFnc,  getParams())
        } catch (e) {
            console.error(e)
            throw e
        }
    }, 
    {   
/*         onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
            console.log('onErrorRetry', error, retryCount)
        
            if (retryCount >= 5) return
        
            // Retry after 5 seconds.
            setTimeout(() => revalidate({ retryCount }), 5000)
        }, */
        ...otherOptions
    })

    const { data, error, mutate } = swr

    let isValidating = null

    if (stateDependencies.isValidating) {
        isValidating = swr.isValidating
    }
/*     if (stateDependencies.error) {
        error = swr.error
    } */

    //console.log('useResolvedV2 '+key+' rerender')

    const convertIfNecessary = (data) => {
        if (!converter) {
            //if no converter, nothing to to. converter fields not used
            return data;
        }

        return converter(data)
    }

    const toDataWrapped = (data) => {

       // console.debug(fetchKey, 'toDataWrapped', data)

        if (data._parsed && data.content) {//was prepared by fetchAndParse, or mutated with page info
            //console.debug('handleData considered as _parsed ' + fetchKey)
            return {
                content: convertIfNecessary(data.content),
                page: data.page
            }
        } 
        else if (data._parsed && data.content === null) {
            return {
                content: null,
                page: data.page
            }
        }
        else { //was directly mutated (such as optimistic data)
            //console.debug('handleData considered as direct mutate ' + fetchKey)
            return {
                content: convertIfNecessary(data),
                page: dataWrapped?.page || null, //keep existing page as there was none given (if one exists)
            }
        }
    }

    const lastKnownData = useRef(null)
    const dataWrappedHolder = useRef(null)

    //console.log(fetchKey + ' stable-hash',(lastKnownData.current?hash(lastKnownData.current):null) === (data?hash(data):null))
    //console.log(fetchKey + ' json-stringfy',(lastKnownData.current?JSON.stringify(lastKnownData.current):null) === (data?JSON.stringify(data):null))

    if (!isEqual(data, lastKnownData.current)) {
        lastKnownData.current = data || null

        if (isHavingValue(dataWrappedHolder.current) && isNullOrUndefined(data)) {
            dataWrappedHolder.current = null
        } else if (isHavingValue(data)) {
            dataWrappedHolder.current = toDataWrapped(data)
        }
    }

    const dataWrapped = dataWrappedHolder.current



    //const [dataWrapped, setDataWrapped] = useState(isHavingValue(data) ? toDataWrapped(data) : null)

/*     useEffect(()=> {
        console.log(fetchKey+ ' data changed')
        handleData()
    },[data]) */

/*     const handleData = () => {
        //console.debug('handleData ', fetchKey, data)
        if (!isHavingValue(data)) {
            if (isHavingValue(dataWrapped?.content)) {
                setDataWrapped(null)
            }
        } else {
            setDataWrapped(toDataWrapped(data))
        }
    } */

    const mutateInternal = (param, options) => {
        //console.debug('mutateInternal', fetchKey)
        if (isHavingValue(param) && isFunction(param)) {
            //console.debug('calling mutate with function', fetchKey)
            return mutate((data)=>{
                if (data._parsed) {
                    return param(data.content)
                } else {
                    return param(data)
                }
            }, options)
        } else if (isHavingValue(param)) {
            return mutate(param, options)
        } else {
            return mutate()
        }
    }

    //there must be no page if data ist not paged
    const pageEffective = dataWrapped?.page ? dataWrapped.page : null
    const itemEffective = dataWrapped ? dataWrapped.content : null

    return {
        item: itemEffective,
        isLoading: !error && !isHavingValue(dataWrapped),
        loading: !error && !isHavingValue(dataWrapped),
        get isValidating() {
            stateDependencies.isValidating = true
            return isValidating
        },
        get error() {
            stateDependencies.error = true
            return error
        },
        refresh: mutateInternal,
        mutate: mutateInternal,
        page: pageEffective,
        setPageIndex,
    }

}