export const ImageCss  = ({image, imageElemProps, width, maxWidth, height, maxHeight, responsive}) => {

    const rootCss = {width: '100%', height: '100%'}

    if (width) {
        rootCss.width = width
    }

    if (height) {
        rootCss.height = height
    }

    if (responsive) {
        rootCss.maxWidth = '100%';
        rootCss.maxHeight = '100%';
    }

    if (maxHeight) {
        rootCss.maxHeight = maxHeight
    }

    if (maxWidth) {
        rootCss.maxWidth = maxWidth
    }

    let imageCss = {
        backgroundSize: 'contain',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100%'
    }

    if (imageElemProps?.css) {
        imageCss = {
            ...imageCss,
            ...imageElemProps.css
        }   
    }

    return (<div css={rootCss}>
        <div style={{backgroundImage: "url('"+image+"')"}} css={imageCss}></div>
    </div>)
}