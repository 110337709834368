import { Box, BoxProps } from "@mui/material"
import { FunctionComponent, ReactNode } from "react"
import { forwardRef } from "react"
import { isNumber } from "utils/numberUtils"
import { orElse } from "utils/objectUtils"

export interface VerticalAlignBoxProps {
    style?: any,
    sx?: any,
    children?: ReactNode,
    gap?: number | string,
    /**
     * spacing is old. use gap instead
     */
    spacing?: number | string
    columnDown?: string
}

export const VerticalAlignBox: FunctionComponent<VerticalAlignBoxProps & BoxProps> = forwardRef<HTMLElement, VerticalAlignBoxProps & BoxProps>(({children, style, sx, gap, spacing, columnDown, ...props},ref) => {

    gap = orElse(orElse(gap, spacing), 2)

    sx = [
        (theme)=>({
            display: 'flex',
            alignItems: 'center',
            gap: isNumber(gap) ? theme.spacing(gap) : gap,
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
    ]

    if (style) {
        sx.push(style)
    }

    if (columnDown) {
        sx.push(theme=>({
            [theme.breakpoints.down(columnDown)]: {
                flexDirection: 'column',
                alignItems: 'stretch',
                gap: isNumber(gap) ? theme.spacing(gap) : gap,
            },
        }))
    }

    return (
        <Box 
            ref={ref} 
            {...props}
            sx={sx}
        >
            {children}
        </Box>
     )

})